<template>
  <v-dialog v-model="show" persistent max-width="400" @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span class="headline"> Change Billing Status </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-container fluid>
        <v-form ref="form" v-model="valid">
          <v-card-subtitle v-if="multipleInvoices">
            {{ totalInvoicesAffectedText }}
          </v-card-subtitle>
          <v-select
            class="pl-4 pr-4"
            label="Billing Status"
            v-model="newStatus"
            :items="dropdownItems"
            item-text="displayName"
            item-value="value"
            return-object
            required
            :color="theme.dropDown"
            :item-color="theme.dropDownItem"
            @change="resetNote"
          ></v-select>
          <v-textarea
            class="px-4"
            label="Note"
            placeholder="Note about cancelation (required)"
            v-if="canceled"
            v-model="cancelationNote"
            dense
            outlined
            rows="4"
            auto-grow
            no-resize
            :rules="noteRules"
            :color="theme.textField"
          ></v-textarea>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :style="theme.textColor"
            :color="theme.buttonWhite"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="confirm"
            :color="theme.button"
            :dark="!disabled"
            :disabled="disabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: [
    "showDialog",
    "theme",
    "multipleInvoices",
    "totalInvoicesAffectedText",
    "newInvoiceStatus",
    "note",
    "dropdownItems",
    "save",
    "resetNote",
    "changedNote",
    "changedStatus"
  ],
  emits: ["update:showDialog", "update:newInvoiceStatus", "update:note"],
  data: () => ({
    valid: false,
  }),
  computed: {
    ...mapState("accountStatus", ["noteMaxCharacters"]),
    noteRules() {
      return [
        v => !!(v || "").trim() || "Note must not be empty",
        v =>
          (v || "").trim().length <= this.noteMaxCharacters ||
          `A maximum of ${this.noteMaxCharacters} characters is allowed`
      ];
    },
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    newStatus: {
      get() {
        return this.newInvoiceStatus;
      },
      set(newValue) {
        this.$emit("update:newInvoiceStatus", newValue);
      }
    },
    cancelationNote: {
      get() {
        return this.note;
      },
      set(newValue) {
        this.$emit("update:note", newValue);
      }
    },
    changedValue() {
      if (this.canceled) {
        return this.changedNote;
      } else {
        return this.changedStatus;
      }
    },
    disabled() {
      return !this.valid || !this.changedValue;
    },
    canceled() {
      return this.newStatus && this.newStatus.value === "Canceled";
    }
  },
  methods: {
    async confirm() {
      await this.save();
      this.close();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    close() {
      this.resetForm();
      this.show = false;
    },
  },
};
</script>
